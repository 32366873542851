import React from 'react';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { ToasterIcon, ToasterMessage } from '../components/Toasters';

const show = (type, title, message, options, actions) => {
	const toaster = { id: uuidv4() };
	toast[type](<ToasterMessage toaster={toaster} title={title} message={message} actions={actions} />, {
		id: toaster.id,
		icon: <ToasterIcon type={type} />,
		...options,
	});
};

const success = (title, message, options = {}, actions = []) => {
	NotificationService.show('success', title, message, options, actions);
};

const error = (title, message, options = {}, actions = []) => {
	NotificationService.show('error', title, message, options, actions);
};

const custom = (title, message, icon = 'information-line', iconColor = 'gray-500', options = {}, actions = []) => {
	const toaster = { id: uuidv4() };
	toast.success(<ToasterMessage toaster={toaster} title={title} message={message} actions={actions} />, {
		id: toaster.id,
		icon: <ToasterIcon icon={icon} iconColor={iconColor} type={'custom'} />,
		...options,
	});
};
const NotificationService = {
	show,
	success,
	error,
	custom,
};

export default NotificationService;
