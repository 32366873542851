const names = {
	AuthorizedDefaultRoute: `/sites`,
	UnAuthorizedDefaultRoute: `/`,

	Login: `/`,

	Sites: `/sites`,
	SiteTasks: `/sites/tasks`,

	Servers: `/servers`,
};

export const PublicRouteNames = [names.UnAuthorizedDefaultRoute];

export default names;
