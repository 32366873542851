import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Images from '../../assessts';
import MenuItem from './MenuItem';
import { logout } from '../../store/User';
import { AppIcon } from '../App';
import { useLocation } from 'react-router';
import RouteNames from '../../router/RouteNames';

const routes = [
	{
		path: RouteNames.Sites,
		text: 'Sites',
	},
	// {
	// 	path: RouteNames.Servers,
	// 	text: 'Servers',
	// },
];

const Header = () => {
	const navigateTo = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [isMobileMenuVisible, setIsMobileMenuVisible] = React.useState(false);

	const { user } = useSelector(({ user }) => ({
		user: user.user,
	}));

	const isLoggedIn = user && !!user.id;

	const _logout = () => {
		dispatch(logout());
		navigateTo(RouteNames.UnAuthorizedDefaultRoute);
	};

	return (
		<nav className='duassis-header'>
			<div className='desktop-container'>
				<div className='flex justify-between'>
					<div className='flex'>
						<div onClick={() => navigateTo('/home')} className='logo-container'>
							<img src={Images.Logo} alt='Logo' className={'h-10 w-10 object-contain'} />
						</div>
						{isLoggedIn && (
							<div className={'hidden md:flex items-center'}>
								{routes.map((route) => (
									<MenuItem
										key={route.path}
										text={route.text}
										onClick={() => navigateTo(route.path)}
										isActive={location.pathname.indexOf(route.path) > -1}
									/>
								))}
							</div>
						)}
					</div>
					{isLoggedIn && (
						<div className='hidden md:flex items-center space-x-3 '>
							<MenuItem text={'Logout'} onClick={_logout} />
						</div>
					)}
					{isLoggedIn && (
						<div className='md:hidden flex items-center'>
							<button
								className='outline-none'
								onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
							>
								<AppIcon icon={'menu-line'} />
							</button>
						</div>
					)}
				</div>
			</div>
			<div className={`mobile-container ${isMobileMenuVisible ? 'opened' : 'closed'}`}>
				<ul className='flex flex-1 flex-col'>
					{routes.map((route) => {
						if (isLoggedIn) {
							return (
								<li key={route.path}>
									<span
										onClick={() => navigateTo(route.path)}
										className={`block text-sm px-2 py-4 font-semibold uppercase ${
											location.pathname === route.path ? 'text-white bg-primary' : 'text-primary'
										}`}
									>
										{route.text}
									</span>
								</li>
							);
						}
						return null;
					})}
				</ul>
				<ul>
					{isLoggedIn && (
						<li>
							<span
								className='block text-sm text-center px-2 py-4 text-primary font-semibold'
								onClick={_logout}
							>
								Logout
							</span>
						</li>
					)}
				</ul>
			</div>
		</nav>
	);
};

export default Header;
