import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from '../services/UserService';
import NotificationService from '../services/NotificationService';

export const login = createAsyncThunk('login', async ({ email, password }) => {
	return await UserService.login({ email, password });
});

export const getUserData = createAsyncThunk('getUserData', async () => {
	return await UserService.getUserData();
});

export const logout = createAsyncThunk('logout', async () => {
	return await UserService.logout();
});

export const changePassword = createAsyncThunk('changePassword', async (data) => {
	return await UserService.changePassword(data);
});

const slice = createSlice({
	name: 'User',
	initialState: {
		initialLoading: true,
		loading: false,
		loginError: '',
		user: {},
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(login.pending, (state) => {
			state.loading = true;
			state.loginError = '';
		});
		builder.addCase(login.fulfilled, (state, action) => {
			state.user = action.payload;
			state.loginError = '';
			state.loading = false;
		});
		builder.addCase(login.rejected, (state) => {
			state.user = {};
			state.loading = false;
			state.loginError =
				"We're sorry, but there was an error with your Email/ Password combination. Please try again.";
		});

		builder.addCase(logout.fulfilled, (state) => {
			UserService.removeAuthCookie();
			state.user = {};
		});

		builder.addCase(getUserData.fulfilled, (state, action) => {
			state.user = action.payload;
			state.initialLoading = false;
		});
		builder.addCase(getUserData.rejected, (state) => {
			state.user = {};
			state.initialLoading = false;
		});

		builder.addCase(changePassword.pending, (state, action) => {
			state.changingPassword = true;
		});
		builder.addCase(changePassword.fulfilled, (state, action) => {
			state.changingPassword = false;
			NotificationService.success('Your password was successfully changed!');
		});
		builder.addCase(changePassword.rejected, (state, action) => {
			state.changingPassword = false;
			NotificationService.error(action.error.message);
		});
	},
});

const { reducer } = slice;

export default reducer;
