import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SiteService from '../services/SiteService';
import NotificationService from '../services/NotificationService';

export const getSites = createAsyncThunk('getSites', async () => {
	return await SiteService.list();
});

export const getSiteDetails = createAsyncThunk('getSiteDetails', async (id) => {
	return await SiteService.get(id);
});

export const getTasks = createAsyncThunk('getTasks', async (id) => {
	return await SiteService.tasks();
});

export const runSiteTask = createAsyncThunk('runSiteTask', async (payload) => {
	return await SiteService.run(payload);
});

const slice = createSlice({
	name: 'Site',
	initialState: {
		loading: false,
		sites: [],
		details: {},
		tasks: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getSites.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getSites.fulfilled, (state, action) => {
			state.sites = action.payload;
			state.loading = false;
		});
		builder.addCase(getSites.rejected, (state) => {
			state.sites = [];
			state.loading = false;
			NotificationService.error(
				'There was an error that occurred during data retrieval. Please try again and if the problem persists contact the support team.'
			);
		});

		builder.addCase(getSiteDetails.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getSiteDetails.fulfilled, (state, action) => {
			state.details = action.payload;
			state.loading = false;
		});
		builder.addCase(getSiteDetails.rejected, (state) => {
			state.details = {};
			state.loading = false;
			NotificationService.error(
				'There was an error that occurred during data retrieval. Please try again and if the problem persists contact the support team.'
			);
		});

		builder.addCase(getTasks.fulfilled, (state, action) => {
			state.tasks = action.payload;
		});

		builder.addCase(runSiteTask.pending, (state) => {
			state.running = true;
		});
		builder.addCase(runSiteTask.fulfilled, (state) => {
			state.running = false;
		});
		builder.addCase(runSiteTask.rejected, (state) => {
			state.running = false;
			NotificationService.error(
				'There was an error that occurred during the run. Please try again and if the problem persists contact the support team.'
			);
		});
	},
});

const { reducer } = slice;

export default reducer;
