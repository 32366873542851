import React from 'react';
import { AppButton } from './index';

const CopyToClipboard = ({ value, tooltip }) => {
	const copyText = () => {
		navigator.clipboard.writeText(value);
	};

	return <AppButton className={'btn btn-action'} icon={'clipboard-line'} tooltip={tooltip} onClick={copyText} />;
};

export default CopyToClipboard;
