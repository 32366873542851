import React from 'react';
import { AppLayout } from '../../components/Layouts';
import { getSites } from '../../store/Site';
import { useDispatch, useSelector } from 'react-redux';
import { AppButton, AppNoData, AppSection } from '../../components/App';
import { SiteForm, SiteListItem } from '../../components/Site';

const List = () => {
	const [isFormOpen, setIsFormOpen] = React.useState(false);
	const [editSite, setEditSite] = React.useState({});
	const dispatch = useDispatch();

	const { loading, sites } = useSelector(({ site }) => ({
		sites: site.sites,
		loading: site.loading,
	}));

	const _getData = React.useCallback(() => {
		dispatch(getSites());
	}, []);

	React.useEffect(() => {
		_getData();
	}, []);

	React.useEffect(() => {
		if (!isFormOpen) {
			setEditSite({});
		}
	}, [isFormOpen]);

	const onCreate = () => {
		setIsFormOpen(true);
	};

	return (
		<AppLayout loading={loading}>
			<div className={'flex flex-row mb-4 items-center'}>
				<h1 className='flex flex-1 d-text-2xl font-medium'>Sites</h1>
				<AppButton
					icon={'add-line'}
					className={'btn btn-secondary'}
					onClick={onCreate}
					tooltip={'Create a new Site'}
				/>
			</div>

			{sites.length === 0 ? (
				<AppSection>
					<AppNoData
						icon={'cloud-off-line'}
						message={
							"We didn't find any site created or associated with you. Please make sure you have been granted access to the site or create a new one by clicking the below button."
						}
						action={'Create Site'}
						onAction={onCreate}
					/>
				</AppSection>
			) : (
				<div className={'grid grid-cols-3 gap-4'}>
					{sites.map((site) => (
						<SiteListItem
							key={site.id}
							site={site}
							onEdit={() => {
								setEditSite(site);
								setIsFormOpen(true);
							}}
						/>
					))}
				</div>
			)}
			<SiteForm isOpen={isFormOpen} site={editSite} onClose={() => setIsFormOpen(false)} />
		</AppLayout>
	);
};

export default List;
