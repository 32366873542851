import moment from 'moment';

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const serializeQueryString = (obj, prefix) => {
	let str = [],
		p;
	for (p in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(p)) {
			let k = prefix ? prefix + '[' + p + ']' : p,
				v = obj[p];
			if (v !== null && v !== undefined) {
				str.push(
					typeof v === 'object'
						? serializeQueryString(v, k)
						: encodeURIComponent(k) + '=' + encodeURIComponent(v)
				);
			}
		}
	}
	return str.join('&');
};

const formatDate = (date, format = 'DD MMM YYYY HH:mm') => {
	return moment(date).format(format);
};

const getQueryParams = (url) => {
	const params = {};
	if (!url) {
		return params;
	}
	const qs = url.split('?')[1];
	if (!qs) {
		return params;
	}
	const pieces = qs.split('&');
	pieces.map((piece) => {
		const tmp = piece.split('=');
		params[tmp[0]] = tmp[1];
		return params;
	});
	return params;
};

const UtilityService = {
	serializeQueryString,
	formatDate,
	getQueryParams,
};

export default UtilityService;
