import React from 'react';
import { AppButton } from '../../components/App';
import { AppLayout } from '../../components/Layouts';
import { getSiteDetails } from '../../store/Site';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import { SiteForm, SiteRunTask, SiteTaskItem } from '../../components/Site';

const Tasks = ({}) => {
	const [isFormOpen, setIsFormOpen] = React.useState(false);
	const [selectedTask, setSelectedTask] = React.useState({});
	const [isRunningTask, setIsRunningTask] = React.useState(false);
	const dispatch = useDispatch();

	const location = useLocation();

	const { loading, site, tasks } = useSelector(({ site }) => ({
		site: site.details,
		loading: site.loading,
		tasks: site.tasks,
	}));

	const id = React.useMemo(() => {
		const { id } = UtilityService.getQueryParams(location.search);
		return id;
	}, [location]);

	const _getData = React.useCallback(() => {
		dispatch(getSiteDetails(id));
	}, [id]);

	React.useEffect(() => {
		_getData();
	}, [_getData]);

	const onEdit = () => {
		setIsFormOpen(true);
	};

	const siteTasks = React.useMemo(() => {
		return tasks.filter(({ technology }) => {
			return technology === site.technology || technology === 'ALL';
		});
	}, [tasks, site.technology]);

	return (
		<AppLayout loading={loading}>
			<div className={'flex flex-row mb-4 items-center'}>
				<h1 className='flex flex-1 d-text-2xl font-medium'>{site.name}</h1>
				<AppButton icon={'edit-line'} className={'btn btn-secondary'} onClick={onEdit} tooltip={'Edit Site'} />
			</div>
			<div className={'grid grid-cols-3 gap-4'}>
				{siteTasks.map((task) => (
					<SiteTaskItem
						key={task.code}
						task={task}
						onRun={() => {
							setSelectedTask(task);
							setIsRunningTask(true);
						}}
					/>
				))}
			</div>
			<SiteRunTask
				site={site}
				isOpen={isRunningTask}
				task={selectedTask}
				onClose={() => setIsRunningTask(false)}
			/>
			<SiteForm isOpen={isFormOpen} site={site} onClose={() => setIsFormOpen(false)} />
		</AppLayout>
	);
};

export default Tasks;
