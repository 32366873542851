import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AppBreakLine, AppButton, AppIcon, AppInput, AppSection } from '../../components/App';
import { emailRegex } from '../../services/UtilityService';
import Images from '../../assessts';
import { login } from '../../store/User';
import { useDispatch, useSelector } from 'react-redux';
import RouteNames from '../../router/RouteNames';

const Login = () => {
	const navigate = useNavigate();

	const { user, loading, errorMessage } = useSelector(({ user }) => ({
		user: user.user,
		loading: user.loading,
		loadingGoogle: user.loadingGoogle,
		errorMessage: user.loginError,
	}));

	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const isLoggedIn = React.useMemo(() => {
		return user && user.id;
	}, [user]);

	React.useEffect(() => {
		if (isLoggedIn) {
			navigate(RouteNames.Sites);
		}
	}, [isLoggedIn]);

	const _login = async (credentials) => {
		const { type } = await dispatch(login(credentials));
		if (type.indexOf('fulfilled') > -1) {
			navigate(RouteNames.Sites);
		}
	};

	return (
		<div className={'flex flex-1 items-center content-center mx-4 md:mx-auto h-screen md:w-1/2 lg:w-1/3 xl:w-1/4 '}>
			<AppSection>
				<img src={Images.Logo} alt='' className={'w-32 object-contain mx-auto'} />
				{!!errorMessage && (
					<div className={'flex flex-1 bg-error text-white p-4 shadow-md my-4'}>
						<AppIcon icon={'close-line'} className={'text-4xl'} />
						<p>{errorMessage}</p>
					</div>
				)}
				<form className={'w-full mb-3'} onSubmit={handleSubmit(_login)}>
					<AppInput
						label={'Email'}
						placeholder={'Email'}
						errors={errors}
						{...register('email', {
							required: true,
							pattern: {
								value: emailRegex,
							},
						})}
					/>
					<AppInput
						label={'Password'}
						placeholder={'Password'}
						type={'password'}
						errors={errors}
						{...register('password', {
							required: true,
						})}
					/>
					<AppButton
						type='submit'
						className={'btn btn-primary w-full mb-4'}
						text={'Login'}
						loading={loading}
					/>
				</form>
				<AppBreakLine />
				<div className={'flex flex-col text-center w-full mx-auto mt-6'}>
					<p>Do not have an account? Click the below button to register.</p>

					<AppButton type='button' className={'btn btn-secondary w-full my-4'} text={'Register'} />
				</div>
			</AppSection>
		</div>
	);
};

export default Login;
