import React from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Images from '../../assessts';
import { AppButton, AppSection } from '../App';
import RouteNames from '../../router/RouteNames';

const ListItem = ({ site, onEdit }) => {
	const navigate = useNavigate();

	return (
		<AppSection key={site.id} className={'relative'}>
			<p className={'d-text-lg font-medium'}>{site.name}</p>
			<img
				src={Images.Technology[site.technology]}
				alt=''
				className={'absolute right-0 w-16 h-16 mr-4 mt-4 top-0 object-contain'}
			/>
			<div className='flex flex-row gap-4 mt-4'>
				<AppButton
					icon={'git-repository-line'}
					tooltip={'Git Repository'}
					className={'btn btn-action'}
					onClick={() => window.open(site.repository)}
				/>
				<AppButton icon={'edit-line'} tooltip={'Edit Site'} className={'btn btn-action'} onClick={onEdit} />
				<AppButton
					icon={'tools-line'}
					tooltip={'Run Tasks'}
					className={'btn btn-action'}
					onClick={() =>
						navigate({
							pathname: RouteNames.SiteTasks,
							search: createSearchParams({
								id: site.id,
							}).toString(),
						})
					}
				/>
			</div>
		</AppSection>
	);
};

export default ListItem;
