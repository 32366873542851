import React from 'react';
import { AppButton, AppSection } from '../App';

const TaskItem = ({ task, onRun }) => {
	return (
		<AppSection>
			<div className='flex flex-1 flex-col'>
				<p className={'d-text-xl font-medium'}>{task.name}</p>
				<p className={'d-text-sm text-gray-800'}>{task.description}</p>
			</div>
			<div className='flex flex-row gap-4 mt-4'>
				<AppButton icon={'rocket-line'} tooltip={'Run Task'} className={'btn btn-action'} onClick={onRun} />
			</div>
		</AppSection>
	);
};

export default TaskItem;
