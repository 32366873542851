import React from 'react';
import toast from 'react-hot-toast';

const Actions = ({ toaster, actions }) => {
	const [loading, setLoading] = React.useState(false);

	if (!actions || actions.length === 0) {
		return null;
	}

	return (
		<div
			className={`ttg-text-sm mt-3 ${loading ? 'opacity-50' : ''}`}
			style={{ minWidth: '280px', maxWidth: '280px' }}
		>
			{actions.map(({ text, action }, index) => (
				<p
					key={index}
					className={`text-secondary font-semibold ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
					onClick={() => {
						setLoading(true);
						if (!loading) {
							action().then(() => {
								setLoading(false);
								toast.dismiss(toaster.id);
							});
						}
					}}
				>
					{text}
				</p>
			))}
		</div>
	);
};

export default Actions;
