import React from 'react';
import { AppSideModal } from '../App';

const Form = ({ site, isOpen, onClose }) => {
	return (
		<AppSideModal isOpen={isOpen} closeModal={onClose} onRequestClose={onClose}>
			<p>Not implemented yet!</p>
		</AppSideModal>
	);
};

export default Form;
