import ApiService from './ApiService';
import UtilityService from './UtilityService';

const list = (filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`v1/sites?${query}`)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const get = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/sites/${id}`)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const tasks = () => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/tasks`)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const run = ({ siteId, ...filters }) => {
	const query = UtilityService.serializeQueryString(filters);
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/sites/${siteId}/actions/run?${query}`)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const SiteService = {
	list,
	get,
	tasks,
	run,
};

export default SiteService;
