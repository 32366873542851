import React from 'react';
import toast from 'react-hot-toast';
import { AppIcon } from '../App';
import { ToasterActions } from './index';

const Message = ({ toaster, title, message, actions }) => {
	const hasTitle = !!title;

	const titleContent = (
		<p
			className={'ttg-toaster-title ttg-text-sm font-medium'}
			style={{ minWidth: '280px', maxWidth: '280px' }}
			dangerouslySetInnerHTML={{ __html: title }}
		/>
	);

	const descriptionContent = (
		<p
			className={'ttg-toaster-message text-gray-500 ttg-text-sm'}
			style={{ minWidth: '280px', maxWidth: '280px' }}
			dangerouslySetInnerHTML={{ __html: message }}
		/>
	);

	return (
		<div>
			<div className={'flex flex-row'}>
				{hasTitle ? titleContent : descriptionContent}
				<AppIcon
					id={'ttg-toaster-close-button'}
					icon={'close-fill'}
					className={'text-gray-400 cursor-pointer'}
					onClick={() => toast.dismiss(toaster.id)}
				/>
			</div>
			{hasTitle && descriptionContent}

			<ToasterActions actions={actions} toaster={toaster} />
		</div>
	);
};

export default Message;
