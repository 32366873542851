import ApiService from './ApiService';
import UtilityService from './UtilityService';

export const AUTH_COOKIE_NAME = 'DEPLOYER_AUTH_COOKIE';

const filters = {
	include: '',
};

const query = UtilityService.serializeQueryString(filters);

const login = ({ email, password }) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/login?${query}`, { email, password })
			.then((response) => {
				const { token, user } = response.data;
				setAuthCookie(token);
				resolve(user);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getUserData = () => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/me?${query}`)
			.then(({ data }) => {
				const user = data.data;
				resolve(user);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const logout = () => {
	return new Promise((resolve) => {
		ApiService.post('v1/logout').finally(() => {
			resolve();
		});
	});
};

const list = (filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`v1/users?${query}`)
			.then(({ data }) => {
				resolve(data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const changePassword = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/me/actions/changePassword`, payload).then(resolve, reject);
	});
};

export const setAuthCookie = (token) => {
	localStorage.setItem(AUTH_COOKIE_NAME, token);
};

const removeAuthCookie = () => {
	localStorage.removeItem(AUTH_COOKIE_NAME);
};

const isAdmin = (user) => {
	try {
		return user.role.toUpperCase() === 'ADMIN';
	} catch (e) {
		return false;
	}
};

const UserService = {
	login,
	getUserData,
	logout,
	list,
	isAdmin,
	removeAuthCookie,
	changePassword,
};

export default UserService;
