import React from 'react';
import { AppIcon } from '../App';

const ToasterIcon = ({ icon, iconColor, type = 'success' }) => {
	const styles = {
		success: {
			color: 'success',
			icon: 'checkbox-circle-line',
		},
		error: {
			color: 'error',
			icon: 'error-warning-line',
		},
		custom: {
			color: iconColor,
			icon: icon,
		},
	};

	return (
		<AppIcon
			icon={styles[type].icon}
			className={`ttg-toaster-icon text-${styles[type].color} ttg-text-xl text-center`}
		/>
	);
};

export default ToasterIcon;
