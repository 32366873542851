import React from 'react';
import { HashRouter as AppRouter, Route, Routes } from 'react-router-dom';
import { Login, NotFound, Sites, SiteTasks } from '../views';
import { useSelector } from 'react-redux';
import RouteNames from './RouteNames';
import ProtectedRoute from './ProtectedRoute';

const Router = () => {
	const { user } = useSelector(({ user }) => ({
		user: user.user,
	}));

	const isLoggedIn = React.useMemo(() => {
		return user && user.id;
	}, [user]);

	return (
		<AppRouter>
			<Routes>
				{/* Not Authenticated Routes */}
				<Route exact path={RouteNames.Login} element={<Login />} />
				{/*Authenticated Routes*/}
				<Route
					exact
					path={RouteNames.Sites}
					element={
						<ProtectedRoute isLoggedIn={isLoggedIn}>
							<Sites />
						</ProtectedRoute>
					}
				/>
				<Route
					exact
					path={RouteNames.SiteTasks}
					element={
						<ProtectedRoute isLoggedIn={isLoggedIn}>
							<SiteTasks />
						</ProtectedRoute>
					}
				/>
				{/* NotFound Error page */}
				<Route path={'*'} element={<NotFound />} />/
			</Routes>
		</AppRouter>
	);
};

export default Router;
