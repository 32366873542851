import { useCallback, useEffect } from 'react';
import Router from './router';
import { AppLoading } from './components/App';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from './store/User';
import { getTasks } from './store/Site';
import { Toaster } from 'react-hot-toast';

const App = ({ ...props }) => {
	const loading = useSelector(({ user }) => user.initialLoading);
	const dispatch = useDispatch();

	const _getUserData = useCallback(() => {
		dispatch(getUserData());
	}, [dispatch]);

	const _getTasks = useCallback(() => {
		dispatch(getTasks());
	}, [dispatch]);

	useEffect(() => {
		_getUserData();
		_getTasks();
	}, [_getUserData, _getTasks]);

	if (loading) {
		return <AppLoading />;
	}

	return (
		<>
			<Toaster
				position='top-right'
				toastOptions={{
					className: 'd-toaster',
					success: {
						duration: 5000,
					},
				}}
				containerStyle={{
					top: 50,
				}}
			/>
			<Router {...props} />
		</>
	);
};

export default App;
