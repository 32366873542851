import React from 'react';
import { AppButton, AppIcon } from './index';

const NoData = ({ message, action, onAction, icon = 'information-fill' }) => {
	return (
		<div className={'text-center mt-4 max-w-screen-sm mx-auto'}>
			<AppIcon icon={icon} className={'d-text-8xl text-primary'} />
			<p className={'d-text-sm'}>{message}</p>
			{!!action && <AppButton className={'btn btn-secondary mt-4'} text={action} onClick={onAction} />}
		</div>
	);
};

export default NoData;
