import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppButton, AppCopyToClipboard, AppInput, AppSideModal } from '../App';
import NotificationService from '../../services/NotificationService';
import { runSiteTask } from '../../store/Site';

const RunTask = ({ site, task, isOpen, onClose }) => {
	const [params, setParams] = React.useState({});
	const [logs, setLogs] = React.useState([]);

	const dispatch = useDispatch();

	const { loading } = useSelector(({ site }) => ({
		loading: site.running,
	}));

	React.useEffect(() => {
		if (!isOpen) {
			setParams({});
			setLogs([]);
		}
	}, [isOpen]);

	const _run = async () => {
		setLogs([]);
		if (task.params && task.params.length > 0) {
			const notInserted = task.params.filter((p) => !params[p.key]).length > 0;
			if (notInserted > 0) {
				NotificationService.error('Please insert the necessary information.');
				return;
			}
		}
		const { type, payload } = await dispatch(
			runSiteTask({
				siteId: site.id,
				code: task.code,
				id: site.id,
				...params,
			})
		);
		if (type.indexOf('fulfilled') > -1) {
			setLogs(payload);
		}
	};

	return (
		<AppSideModal
			isOpen={isOpen}
			closeModal={onClose}
			onRequestClose={() => {
				if (loading) {
					NotificationService.custom("A task run is in progress, please wait until it's finished.");
				} else {
					onClose();
				}
			}}
		>
			<p className={'d-text-xl font-medium'}>{task.name}</p>
			<p className={'d-text-sm text-gray-800 mb-4'}>{task.description}</p>
			{task.params && task.params.length > 0 && (
				<div>
					{task.params.map((param) => (
						<div key={param.key}>
							<AppInput
								label={param.name}
								value={[params[param.key]]}
								onChange={(event) => {
									setParams((s) => ({
										...s,
										[param.key]: event.target.value,
									}));
								}}
							/>
						</div>
					))}
				</div>
			)}
			<div>
				<p className={'d-text-sm mb-4'}>
					By clicking the Run button, the <span className='font-medium'>{task.name}</span> process will start.
					Please note that this process might take a while. Once finished, you will be shown a full log
					information to check if the process finished successfully or not.
				</p>
			</div>
			{logs.length > 0 && (
				<pre className={'code'}>
					{logs.map((log, index) => (
						<p key={index} className={'d-text-sm'} dangerouslySetInnerHTML={{ __html: log }} />
					))}
				</pre>
			)}
			<div className={'flex gap-4'}>
				{logs.length === 0 ? (
					<>
						<AppButton className={'w-full btn btn-primary'} loading={loading} text={'Run'} onClick={_run} />
						<AppButton
							className={'w-full btn btn-secondary'}
							loading={loading}
							text={'Cancel'}
							onClick={onClose}
						/>
					</>
				) : (
					<>
						<AppCopyToClipboard value={logs.join('')} tooltip={'Copy logs to clipboard'} />
						<AppButton className={'w-full btn btn-secondary'} text={'Close'} onClick={onClose} />
					</>
				)}
			</div>
		</AppSideModal>
	);
};

export default RunTask;
