import React from 'react';
import { useLocation } from 'react-router-dom';
import RouteNames from './RouteNames';

const ProtectedRoute = ({ isLoggedIn, children }) => {
	const location = useLocation();

	if (!isLoggedIn) {
		window.location.href = `${RouteNames.Login}?redirect=${encodeURIComponent(location.pathname)}${
			location.search
		}`;
		return null;
	}

	return children;
};

export default ProtectedRoute;
